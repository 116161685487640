// This intermediate file is used for treeshaking
// It allows us to do "import * as Icons" and to then
// access the icons as <Icons[icon] /> without adding all
// possible icons to the bundle.
// https://react-icons.netlify.com/#/
export {
    // people --------------------------------------------------------------------
    FaRegHandPeace,
    FaPeopleCarry,
    // FaPersonBooth,
    // FaUserAltSlash,
    // FaUserAlt,
    // FaUserAstronaut,
    // FaUserCheck,
    // FaUserClock,
    // FaUserCog,
    // FaUserEdit,
    // FaUserFriends,
    // FaUserGraduate,
    // FaUserInjured,
    // FaUserLock,
    // FaUserMd,
    // FaUserMinus,
    // FaUserNinja,
    // FaUserPlus,
    // FaUserSecret,
    // FaUserShield,
    // FaUserSlash,
    // FaUserTag,
    // FaUserTie,
    // FaUserTimes,
    // FaUser,
    // FaUsersCog,
    // FaUsers,
    //
    // Comments and Chats --------------------------------------------------------
    // FaCommentAlt,
    // FaCommentDollar,
    // FaCommentDots,
    // FaCommentMedical,
    // FaCommentSlash,
    // FaComment,
    // FaCommentsDollar,
    // FaComments,
    //
    // Communications ------------------------------------------------------------
    // FaMailBulk,
    // FaEnvelopeOpenText,
    // FaEnvelopeOpen,
    // FaEnvelopeSquare,
    // FaEnvelope,
    // FaMobile,
    //
    // Tools and Settings --------------------------------------------------------
    // FaTachometerAlt,
    // FaCogs,
    // FaWrench,
    // FaLockOpen,
    // FaLock,
    // FaTrash,
    // FaTrashAlt,
    // FaPen,
    // FaPencilAlt,
    //
    // Files and Folders ---------------------------------------------------------
    // FaRegFileAlt,
    // FaRegFileArchive,
    // FaRegFileCode,
    // FaRegFileExcel,
    // FaRegFileImage,
    // FaRegFilePdf,
    // FaRegFileVideo,
    // FaRegFile,
    // FaRegFlag,
    // FaRegFolderOpen,
    // FaRegFolder,
    //
    // Information and status ----------------------------------------------------
    // FaCheckCircle,
    // FaCheck,
    // FaExclamationCircle,
    // FaExclamationTriangle,
    // FaExclamation,
    // FaInfoCircle,
    // FaQuestionCircle,
    // FaRegCheckCircle,
    // FaRegCircle,
    //
    // Content and chat ----------------------------------------------------------
    FaBook,
    // FaBookOpen,
    // FaBookReader,
    // FaBookmark,
    //
    // Business and finance ------------------------------------------------------
    // FaCreditCard,
    // FaTruckMoving,
    // FaTruck,
    // FaDollarSign,
    // FaEuroSign,
    FaBox,
    // FaStore,
    // FaStoreAlt,
    //
    // Devices and software ------------------------------------------------------
    // FaDesktop,
    // FaLaptop,
    // FaMobileAlt,
    // FaDatabase,
    // FaRegWindowMaximize,
    //
    // other ---------------------------------------------------------------------
    // FaPiggyBank,
    // FaRegLightbulb,
    // FaRegPaperPlane,
    // FaRegSun,
    // FaMicrophoneAlt,
    FaLightbulb,
    FaRegListAlt,
    FaCog,
    FaQuestion,
    FaUser,
    FaCheck,
    FaMapSigns,
    FaLink,
    FaLeanpub
}
from "react-icons/fa"